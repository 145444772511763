import { urlFilter, filterUtils } from 'o365-filterobject';
import { getMappedId } from 'status.vue.components.layoutHelpers.FilterAppIdMappings.ts';

export type UrlObject = {
    "enable": boolean,
    "application": string,
    "gridDataObject": string,
    "filterString": string,
    "filterParameters": Array<UrlParameter>,
    "applicationParameters": Array<UrlParameter>
}

export type UrlParameter = {
    "name": string,
    "operator": string,
    "value": string,
    "useFreeText": boolean
}

/**
 * Returns url that contains url params and encoded datasource filter.
 * If either application or gridDataObject is not provided returns empty string.
 * 
 * @param {UrlObject} urlObject - The custom URL config object.
 * @param {any} foundItem - Object that will be used to replace values.
 * @returns {string} Builded url string or if failed empty string.
 */
export async function buildUrl(urlObject: UrlObject, foundItem: any): Promise<string> {
    if (!urlObject.application || !urlObject.gridDataObject) {
        return "";
    }

    let currentFilterString = urlObject.filterString ?? "";

    if (urlObject.filterParameters?.length) {
        const filterObjects = urlObject.filterParameters.map(filterParameter => ({
            column: filterParameter.name,
            operator: filterParameter.operator,
            value: foundItem[filterParameter.value],
            valueType: typeof(foundItem[filterParameter.value])
        }));

        const newDynamicFilterString = filterObjects
            .map(filter => filterUtils.filterItemToString(filter))
            .join(" AND ");

        if (!currentFilterString) {
            currentFilterString = newDynamicFilterString;
        } else if (currentFilterString.startsWith("(") && currentFilterString.endsWith(")")) {
            currentFilterString = currentFilterString.slice(0, -1) + " AND " + newDynamicFilterString + ")";
        } else {
            currentFilterString = currentFilterString + " AND " + newDynamicFilterString;
        }
    }

    let compressedFilterString = "";
    if (currentFilterString) {
        compressedFilterString = await urlFilter.getCompressedUrlFilterString(
            currentFilterString,
            getMappedId(urlObject.application),
            urlObject.gridDataObject
        );
    }

    let urlParams = "";
    if (urlObject.applicationParameters?.length) {
        urlParams = urlObject.applicationParameters
            .map(applicationParameter => {
                const value = applicationParameter.useFreeText ? applicationParameter.value : foundItem[applicationParameter.value];
                return `${encodeURIComponent(applicationParameter.name)}=${encodeURIComponent(value)}`;
            })
            .join("&");
    }

    const queryParts: string[] = [];
    if (urlParams) {
        queryParts.push(urlParams);
    }

    if (compressedFilterString) {
        queryParts.push(compressedFilterString);
    }

    let url = "/" + urlObject.application;
    if (queryParts.length > 0) {
        url += "?" + queryParts.join("&");
    }

    return window.location.origin + url;
}